import React from 'react'
import { AccessibilitySeatFilterMenu } from 'components/seatingChart/seatSelection/AccessibilitySeatFilterMenu'
import { DefaultButton } from 'components/form/buttons/Button'
import DropContainer from 'components/common/containers/DropContainer'
import { OtColors } from 'util/OtColors'
import { SvgWheelchairNoColor } from 'components/common/icons/SvgIcons'

export class AccessibilitySeatFilter extends React.PureComponent {
	state = { priceFilterRange: '', blackBorder: false }
	dropContainer = React.createRef()

	render() {
		let { style, ...props } = this.props
		return (
			<DropContainer
				ref={this.dropContainer}
				key='accessibleFilterMenu'
				alignMenu='left'
				className='ot_mapToolFilter'
				id='AccessibleSeating'
				onClose={this.handleSubmit}
				offset={20}
				selectAndClose={true}
				clickAndToggle={false}
				style={style}
			>
				<DefaultButton
					className={`ot_ci_filterButton ot_iconButton d-flex justify-content-center${this.state.blackBorder === true ? 'ot_ci_filterButton_border' : ''}`}
					color={OtColors.black}
				>
					<div className={`ot_filter_wheel_chair p-2`}>
						<SvgWheelchairNoColor />
					</div>
					<div className='p-2'>Accessible Seating</div>
				</DefaultButton>

				<AccessibilitySeatFilterMenu
					{...props}
					dropContainer={this.dropContainer}
				/>
			</DropContainer>
		)
	}
}
