import React, { useEffect, useRef, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'

import { theme, colors } from 'theme'

import { addToCart } from 'apis/cartWebSocketClient'

import Button from 'components/buttons/Button'

import SelectSeatDetails from './SelectSeatDetails'
import { TicketContext } from './ticketContext/TicketContext'

import TicketScrollable from './scrollable/TicketScrollable'

const Popup = styled.div`
	position: absolute;

	width: 90%;
	max-width: 680px;

	border: 1px solid ${colors.outlines.outline};
	box-shadow: ${theme.boxShadows.shadow10};
	border-radius: ${theme.borderRadius.md};
	background-color: ${colors.white};
	z-index: 9999;
	padding: ${theme.padding.smmd};

	margin-bottom: ${theme.margin.smmd}; /* Added margin-bottom */

	top: 50%;
	left: 50%;

	:after {
		position: absolute;
		content: '';
		bottom: -40px;
		height: 40px;
		width: 1px;
	}
`

const ScrollableContainer = styled.div`
	display: flex;
	justify-content: space-between;

	margin-bottom: ${theme.margin.md};
`

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`

const scrollableColumns = {
	promotions: 'promotions',
	ticketTypes: 'ticket types',
}

const SelectSeat = ({
	selectedSeat,
	promos,
	coordinates: position,
	onClosePopup,
	performanceId,
	setSelectedSeats,
}) => {
	const selectedSeatPopupRef = useRef()
	const [ticketForPricing, setTicketForPricing] = useState(0)
	const [isVisible, setIsVisible] = useState(false)

	const [selectedTicket, setSelectedTicket] = useState(null)
	const [selectedPromo, setSelectedPromo] = useState(null)

	const [selectedTicketTitle, setselectedTicketTitle] = useState(null)

	const handleClickOutside = (event) => {
		if (
			selectedSeatPopupRef.current &&
			!selectedSeatPopupRef.current.contains(event.target) &&
			!event.target.closest('#viewportGroup')
		) {
			closePopup()
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		document.addEventListener('mouseup', handleClickOutside)
		return () => {
			document.addEventListener('mousedown', handleClickOutside)
			document.addEventListener('mouseup', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		setIsVisible(true)
	}, [position])

	const closePopup = () => {
		setIsVisible(false)
		setTimeout(onClosePopup, 200)
	}

	const handleAddToCart = async () => {
		const promotion = selectedPromo

		const promotionPrice = promotion?.price || 0
		const promotionName =
			promotion?.name === 'Full price' ? null : promotion?.name || null

		const seatObj = {
			sectionId: selectedSeat?.sectionId,
			selectedTicketTypeId: selectedTicket.ticketTypeId,
			selectedTicketRuleId:
				promotion?.ticketRuleId || selectedTicket.selectedTicketRuleId,
			promotionPrice: promotionPrice,
			promotionBOFee: promotion?.convenienceFeeBO || 0,
			promotionName: promotionName,
			name: selectedSeat.sectionName,
			promotionCode:
				promotion?.promotionCode ||
				ticketForPricing.promotionCode ||
				selectedTicket.promotionCode,
			promotionId:
				promotion?.promotionId ||
				ticketForPricing.promotionCode ||
				selectedTicket.promotionId,
			seats: selectedSeat.id,
			performanceId: performanceId,
			seatType: selectedSeat?.priceLevel?.type,
			seatTypeInitial: 'available',
			type: 'MSG',
			number: selectedSeat.number,
			row: selectedSeat.row,
			rowNumber: selectedSeat.rowNumber,
			selectedTicketName: selectedTicket.name,
		}

		try {
			const result = await addToCart(seatObj)
			if (result) setSelectedSeats()
		} catch (error) {
			console.error('Error handleAddToCart:', error)
		}
		closePopup()
	}

	return (
		<Popup
			as={motion.div}
			ref={selectedSeatPopupRef}
			initial={{ scale: 0, opacity: 0, transform: 'translate(-50%, -50%)' }}
			animate={isVisible ? { scale: 1, opacity: 1 } : { scale: 0, opacity: 0 }}
			transition={{ type: 'spring', stiffness: 300, damping: 30 }}
		>
			<TicketContext.Provider
				value={{
					selectedTicket,
					setSelectedTicket,

					selectedPromo,
					setSelectedPromo,

					ticketForPricing,
					setTicketForPricing,

					selectedTicketTitle,
					setselectedTicketTitle,
				}}
			>
				<SelectSeatDetails
					selectedSeat={selectedSeat}
					closePopup={closePopup}
				/>
				<AnimatePresence>
					<ScrollableContainer>
						<TicketScrollable
							title={scrollableColumns.promotions}
							promos={promos}
							isPromoTicket={true}
							tickets={selectedTicket?.promotion || []}
						/>
						<TicketScrollable
							title={scrollableColumns.ticketTypes}
							promos={promos}
							isPromoTicket={false}
							tickets={selectedSeat.priceLevel.ticketTypes}
						/>
					</ScrollableContainer>
				</AnimatePresence>
				<ButtonContainer>
					<Button fullWidth onClick={handleAddToCart}>
						Add to cart
					</Button>
				</ButtonContainer>
			</TicketContext.Provider>
		</Popup>
	)
}

export default SelectSeat
