import React, { useEffect } from 'react';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import Cookies from 'js-cookie';

const getLast12Characters = (token) => {
  if (typeof token !== 'string' || token.length < 12) {
    throw new Error('Invalid token provided');
  }
  return token.slice(-12);
}

const WebSocketClient = ({ onMessage }) => {
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      console.error('Token is not available in session storage');
      return;
    }

    const socketUrl = `${process.env.REACT_APP_WEBSOCKET_SUBSCRIPTION_URL}/websocket/register`;
    console.log(`SC Connecting to WebSocket at ${socketUrl}`);
    const tokenPart = getLast12Characters(token);

    const sock = new SockJS(socketUrl);
    const client = new Client({
      webSocketFactory: () => sock,
      connectHeaders: {
        Authorization: `Bearer ${token}`,
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });

    client.onConnect = () => {
      console.log('Connected to WebSocket server');

      // Subscribe to the '/websocket/removeSeat' topic
      client.subscribe('/topic/removeSeat/' + tokenPart, (message) => {
        const data = JSON.parse(message.body);
        onMessage(data);
      });
    };

    client.onStompError = (frame) => {
      console.error('Broker reported error: ' + frame.headers['message']);
      console.error('Additional details: ' + frame.body);
    };

    client.activate();

    return () => {
      client.deactivate();
    };
  }, [onMessage]);

  return <div />;
};

export default WebSocketClient;
