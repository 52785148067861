import { Cache } from 'util/Cache'
import CalendarActionCreator from 'factory/CalendarActionCreator'
import { EditorConstants } from 'components/seatingChart/editor/EditorConstants'
import {
	EditorEvents,
	ClientSeatingChartEvents,
	ClientTicketSelectionEvents,
	CalendarEvents,
	CheckoutEvents,
	CartEvents,
	GeneralStates,
	OTContextEvents,
} from 'actions/Events'

let _loadStates = Symbol('loadStates')
let _loadStatesForEditor = Symbol('loadStatesForEditor')
let _seatingCharts = Symbol('seatingCharts')
let _seatingChartForEditor = Symbol('seatingChartForEditor')
let _chartReloadInfo = Symbol('chartReloadInfo')

const { LAYOUT_TOOLS, ACTION_TOOLS } = EditorConstants

const RESET_TOOL = {
	layout: LAYOUT_TOOLS[1],
	create: null,
	action: ACTION_TOOLS[2],
	property: null,
	outline: null,
	restrictOutline: true,
}

/**
 * SeatingChartStore // "reducer wrapper"
 *
 * Note: DO NOT ASSIGN Object.assign() on an existing object. ALWAYS assign to a new {}
 * eg. Object.assign(selectedObject, data) vs Object.assign({}, selectedObject, data)
 * The latter is the correct way to do it.
 */
export class SeatingChartStore {
	isProcessing = false

	selectedObject = null

	newObjects = []

	updatedObjects = {
		SECTION: {},
		OBJECT: {},
	}

	removedObjects = {}

	undoSectionHistory = {}

	redoSectionHistory = {}

	tool = RESET_TOOL

	anchor = null

	view = {
		minPoint: [0, 0], // deprecated, use chart.minPoint
		chartSize: [0, 0],
		chart: {
			minPoint: [0, 0],
			maxPoint: [0, 0],
			centerPoint: [0, 0],
		},
		scale: {
			current: null,
			home: null,
			max: 2,
			min: 0.25, //.001
		},
		pan: {
			dX: 0,
			dY: 0,
		},
		viewPort: null,
		viewPortChartCenter: [0, 0],
	}

	clientView = {
		pan: {
			dX: 0,
			dY: 0,
		},
	}

	selectedSeats = {}

	temporarySelectedSeat = null

	constructor() {
		this[_loadStates] = new Cache()
		this[_loadStatesForEditor] = new Cache()
		this[_seatingCharts] = new Cache()
		this[_seatingChartForEditor] = new Cache()
		this[_chartReloadInfo] = null
	}

	seatingChartReducer(_state, action) {
		if (
			action.type === CartEvents.CartChange.CART_CLEARED ||
			action.type === CartEvents.CartChange.ITEM_REMOVED ||
			(action.type === CartEvents.CartChange.ITEM_ADDED &&
				(action.data.type === 'TICKETS' ||
					action.data.type === 'PACKAGES' ||
					action.data.type === 'DONATIONS')) ||
			action.type === CheckoutEvents.PROMOTION_CODE_ADDED ||
			action.type === CheckoutEvents.PROMOTION_CODE_REMOVED ||
			action.type === OTContextEvents.CONSUMER_LOGIN ||
			action.type === OTContextEvents.CONSUMER_LOGOUT
		) {
			this[_loadStates].purge()
			this[_seatingCharts].purge()
		}
		if (action.type === CalendarEvents.SEATING_CHART_BY_ID_LOADED) {
			this[_loadStatesForEditor].insert(action.data.id, GeneralStates.DONE)
			this[_seatingChartForEditor].insert(action.data.id, {
				hasSeatingChart: true,
				seatingChart: action.data,
			})
		}
		if (action.type === CalendarEvents.SEATING_CHART_LOADED) {
			this[_loadStates].insert(action.data.performanceId, GeneralStates.DONE)
			this[_seatingCharts].insert(action.data.performanceId, {
				hasSeatingChart: true,
				seatingChart: action.data,
			})
		}
		if (action.type === CalendarEvents.NO_SEATING_CHART) {
			this[_loadStates].insert(action.data.performanceId, GeneralStates.DONE)
			this[_seatingCharts].insert(action.data.performanceId, {
				hasSeatingChart: false,
			})
		}

		switch (action.type) {
			case EditorEvents.INIT_VIEW:
				this.view = {
					...this.view,
					...action.view,
				}
				break
			case ClientSeatingChartEvents.DRAG_SEATING_CHART:
				if (action.dX) {
					this.clientView.pan.dX += action.dX
				}
				if (action.dY) {
					this.clientView.pan.dY += action.dY
				}
				break
			case ClientSeatingChartEvents.RESET_DRAG_SEATING_CHART:
				this.clientView.pan.dX = 0
				this.clientView.pan.dY = 0
				break
			case ClientTicketSelectionEvents.SELECT_TEMPORARY_SEAT:
				if (action.seat) {
					this.temporarySelectedSeat = action.seat
				}
				break
			case ClientTicketSelectionEvents.ADD_SELECTED_SEATS:
				if (action.selectedSeats) {
					this.selectedSeats = {
						...action.selectedSeats,
					}
				}
				break
		}

		return {
			seatingCharts: this,
		}
	}
	getSelectedObject() {
		return this.selectedObject
	}
	getNewObjects() {
		return this.newObjects
	}
	getUpdatedObjects() {
		return this.updatedObjects
	}
	getRemovedObjects() {
		return this.removedObjects
	}
	getTool() {
		return this.tool
	}
	getAnchor() {
		return this.anchor
	}
	getViewPort() {
		return this.view.viewPort
	}
	getViewPortChartCenter() {
		return this.view.viewPortChartCenter
	}
	getViewScale() {
		return this.view.scale
	}
	getViewPan() {
		return this.view.pan
	}
	getIsProcessing() {
		return this.isProcessing
	}
	getUndoSectionHistory() {
		return this.undoSectionHistory
	}
	getRedoSectionHistory() {
		return this.redoSectionHistory
	}
	getClientViewPan() {
		return this.clientView.pan
	}
	getTemporarySelectedSeat() {
		return this.temporarySelectedSeat
	}
	getSelectedSeats() {
		return this.selectedSeats
	}

	async fetchSeatingChartData(
		performanceId,
		clientId,
		deepLink,
		setErrorMessage,
		useSeatingChartAPI
	) {
		let loadState =
			this[_loadStates].get(performanceId) || GeneralStates.NOT_LOADED
		if (loadState !== GeneralStates.LOADING) {
			this[_loadStates].insert(performanceId, GeneralStates.LOADING)
			if (useSeatingChartAPI) {
				const res = await CalendarActionCreator.fetchSeatingChartData(
					performanceId,
					clientId,
					deepLink,
					setErrorMessage
				)

				return res
			}
			const result = await CalendarActionCreator.loadSeatingChart(
				performanceId,
				clientId,
				deepLink,
				setErrorMessage
			)

			return result
		}
	}

	async getSeatingChart(performanceId, clientId, deepLink, setErrorMessage) {
		let chart = this[_seatingCharts].get(performanceId)
		if (!chart) {
			chart = await this.loadSeatingChart(
				performanceId,
				clientId,
				deepLink,
				setErrorMessage
			)
		}
		return chart
	}

	getSeatingChartById(seatingChartId) {
		return this[_seatingChartForEditor].get(seatingChartId)
	}
	getSeatingChartAvailableSeats(performanceId, deepLink) {
		let chart = this.getSeatingChart(performanceId, deepLink)
		if (chart) {
			return
		}
		return null
	}
	loadSeatingChartById(seatingChartId) {
		let loadState =
			this[_loadStatesForEditor].get(seatingChartId) || GeneralStates.NOT_LOADED
		if (loadState !== GeneralStates.LOADING) {
			this[_loadStatesForEditor].insert(seatingChartId, GeneralStates.LOADING)
			CalendarActionCreator.loadSeatingChartById(seatingChartId)
		}
	}
	async loadSeatingChart(performanceId, clientId, deepLink, setErrorMessage) {
		let loadState =
			this[_loadStates].get(performanceId) || GeneralStates.NOT_LOADED
		if (loadState !== GeneralStates.LOADING) {
			this[_loadStates].insert(performanceId, GeneralStates.LOADING)
			return await CalendarActionCreator.loadSeatingChart(
				performanceId,
				clientId,
				deepLink,
				setErrorMessage
			)
		}
	}
	stopRepeatLoad() {
		let currentInterval = this[_chartReloadInfo]
		if (currentInterval && currentInterval.id) {
			window.clearInterval(currentInterval.id)
		}
		this[_chartReloadInfo] = null
	}
	setRepeatedLoad(performanceId, loadTimeInSec = 60) {
		let currentInterval = this[_chartReloadInfo]
		if (currentInterval && currentInterval.performanceId === performanceId) {
			//performance has not changed, do nothing
			return
		}
		this.stopRepeatLoad()
		let id = window.setInterval(
			this.loadSeatingChart.bind(this, performanceId),
			loadTimeInSec * 1000
		)
		this[_chartReloadInfo] = { id, performanceId }
	}
	getAvailableSeatsFromChart(chart, limit) {
		let ret = {}
		let sections = {}
		chart.sections.forEach((sec) => {
			sections[sec.id] = sec
			sec.rows.forEach((row) => {
				row.seats
					.filter((seat) => seat.forSale && seat.available && seat.priceLevel)
					.forEach(() => {
						ret[sec.id] = (ret[sec.id] || 0) + 1
					})
			})
		})
		return Object.map(ret, (value, key) =>
			this.getPriceLevelInfoObject(sections[key], value, limit)
		)
	}
	getPriceLevelInfoObject(section, count, limit = Number.MAX_VALUE) {
		let ticketTypes = [
			{
				ticketTypeId: -1,
				name: section.name,
				price: 0,
				maxTickets: Math.min(limit, count),
				minTickets: 0,
			},
		]
		return {
			ticketGroupName: section.name,
			sectionId: section.id,
			entryTimesApply: false,
			priceLevel: {
				id: -1,
				name: section.name,
			},
			minPrice: 0,
			maxPrice: 0,
			hasRestrictedTicketType: false,
			hasPromotionalPrices: false,
			hasRegularPrices: true,
			ticketTypeViews: ticketTypes,
		}
	}
}
const store = new SeatingChartStore()
export default store
export const seatingChartReducer = store.seatingChartReducer.bind(store)
