import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import configureStore from './reducers/configureStore'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { LoadErrorMessageProvider } from './components/seatingChart/context/LoadErrorMessageContext'

const store = configureStore()
const container = document.getElementById('root')
const root = createRoot(container)

root.render(
	<Provider store={store}>
		<Router>
			<Routes>
				<Route
					path='/seatingChart/:clientId/:performanceId'
					element={
						<LoadErrorMessageProvider>
							<App />
						</LoadErrorMessageProvider>
					}
				/>
			</Routes>
		</Router>
	</Provider>
)
